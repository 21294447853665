@import 'variables';
@import 'accordion';
@import 'maleo-card';

html, body {
  font-size: 16px;

  @media screen and (min-width: 500px) {
    font-size: 22px;
  }

  @media screen and (min-width: 800px) {
    font-size: 24px;
  }
}

.top-navbar {
  border-bottom-color: $brand-color;
}

.top-navbar .top-navbar-right {
  display: none;
}

#slide-out-right {
  display: none;
}


// Slider

.featured-slider,
.maleo-testimonial_slider {
  .slick-dots li.slick-active button {
    background: $brand-color;
  }
}
// Footer

.footer {
  background: $brand-color;

  &,
  .copyright,
  .heading,
  .social-footer a {
    color: white;
  }
}

#to-top {
  background: $brand-color;
  background: rgba(red($brand-color), green($brand-color), blue($brand-color), 0.5);
}

%current-item {
  color: $brand-color;
  font-weight: bold;
}

.fullscreen-img {
    position: relative;

    .image_container {
        &:before {
            content: "\f0b2";
            color: white;
            background: #444;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            padding: 5px;
            font: normal normal normal 16px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

#slide-out-left {
  li {
    &.current-item {
      > .collapsible-header a.current-item,
       > .collapsible-header span.angle-right,
       > a,
      > .collapsible-header i.current-item {
        @extend %current-item;

        i {
          color: $brand-color;
        }
      }
    }

    a {
      padding-left: 25px;

      .fa {
        width: 20px;
      }
    }

    .collapsible-header {
      &.current-item {
        > .angle-right,
         > i,
        > a {
          @extend %current-item;
        }
      }

      > a {
        padding-left: 0;
        text-decoration: none;
        color: #000;
      }
    }

    .collapsible-body {
      li.current-item a {
        @extend %current-item;
      }
    }
  }

  > ul > li {
    > .collapsible-header {
      margin: 0 1rem 0 1.5rem;
    }

    i.fa, a i.fa {
      width: 30px;
      font-size: inherit;
      text-align: center;
    }
  }
}

ul.custom-list, .custom-list > ul {
   padding-left: 20px;

   li {
     list-style-position: outside;
   }

   @media screen and (min-width: 500px) {
     padding-left: 30px;
   }
}

.maleo-card {
  > .front-ads {
    width: 49%;

    &:nth-child(even),
    &:nth-child(odd) {
      margin-bottom: 2%;
    }

    &:nth-child(odd) {
      margin-right: 1%;
    }

    &:nth-child(even) {
      margin-left: 1%;
    }
  }
}

#iframe-wetter {
  overflow: hidden;
  border-radius: 10px;
  width: 300px;
  height: 365px;
}

.featured-item.fs-possible {
	  position: relative;
    display: inline-block;

    .thumbnail {
      max-width: 100%;

      img {
        width:100%
      }
    }

    .fullscreen {
	    position: absolute;
	    top: 0px;
	    right: 0px;

	    a {
	    	font-size: 18px;
		    padding: 3px 7px;
		    display: inline-block;
		    background: #000;
		    background: rgba(0,0,0,0.5);
		    color: #fff;
		    text-decoration: none;
	    }
    }
}

.image-navigation {
    padding-bottom: 15px;

    .front-ads {
      h3 {
        font-size: 18px;

        @media screen and (min-width: 500px) {
          font-size: 20px;
        }

        @media screen and (min-width: 800px) {
          font-size: 24px;
        }
      }
    }
}

.featured-slider .featured-item .caption h2 {
  @media screen and (min-width: 800px) {
    font-size: 30px;
  }
}

.front-ads {
    .thumb {
        height: 100%;

        img {
            height: inherit;
        }
    }

    @media screen and (min-width: 500px) {
      height: 190px;
    }
}

.page-title {
  font-size: 22px;

  @media screen and (min-width: 500px) {
    font-size: 26px;
  }
}

.maleo-blog .maleo-blog_thumb .thumb-navigation .blog-title {
    font-size: 22px;

    @media screen and (min-width: 500px) {
      font-size: 26px;
    }
}

.maleo-blog_small {
    .maleo-blog_rightcontent{
        .blog-title {
            color: #444;
        }
    }
}

@media screen and (min-width: 500px) {
    .maleo-blog_small {
        .maleo-blog_rightcontent{
            .blog-title {
                font-size: 26px;
            }

            .blog-meta {
                font-size: 18px;
            }
        }
    }
}

.ce_image {
  max-width: 100%;
  width: 100%;
  height: auto;

  figure {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.ce_gallery {
  ul {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;

    li {
      list-style: none;

      figure {
        -webkit-margin-before: 0;
        -webkit-margin-after: 0;
        -webkit-margin-start: 0;
        -webkit-margin-end: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

body.wartungsmodus {
  main .inside {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30em;
    height: 18em;
    margin-top: -9em;
    /*set to a negative number 1/2 of your height*/
    margin-left: -15em;
    /*set to a negative number 1/2 of your width*/
    border: 1px solid #ccc;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.thumb {
  > .image_container {
    margin: 0;
  }
}

.info-box {
  display: none;
}

.content-container {
  min-height: calc(100vh - #{$header-bar-box-sizing} - #{$footer-bar-box-sizing});
}

.overview-big {
  .maleo-blog {
    .maleo-blog_thumb {
      .thumb-overlay {
        z-index: 10;
        opacity: 0.5;
      }
      img {
        transform: translateY(-25%);
      }
      .thumb-navigation {
        z-index: 11;
      }
    }
  }

  @media screen and (min-width: 500px) {
    .maleo-blog {
      .maleo-blog_thumb {
        max-height: 260px;
      }
    }
  }

  @media screen and (min-width: 650px) {
    .maleo-blog {
      .maleo-blog_thumb {
        max-height: 300px;
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .image-small.ce_image {
    width: 40%;
    float: right;
    margin: 0 auto 15px 15px;
  }
}
