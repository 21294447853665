.maleo-blog_small {
  &.big {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .maleo-blog_thumb {
      width: 170px;
    }

    .maleo-blog_rightcontent {
      width: calc(100% - 180px);
    }
  }
}
